import {IconBrandLinkedin, IconBrandGithub} from "@tabler/icons-react";

function SocialMedia() {
  return (
    <>
      <aside className="flex-col fixed z-20 bottom-0 right-12 items-center justify-center gap-4 text-principal-400 dark:text-principal-100 hidden md:flex">
        <a href="https://www.linkedin.com/in/eduardo-lins-751650181/" target="_blank" rel="noreferrer"><IconBrandLinkedin size={25} className="hover:text-principal-700 dark:hover:text-principal-300 cursor-pointer" /></a>
        <a href="https://github.com/Brindize" target="_blank" rel="noreferrer"><IconBrandGithub size={25} className="hover:text-principal-700 dark:hover:text-principal-300 cursor-pointer" /></a>
        <div className="h-24 border-l mt-2 border-principal-400 dark:border-principal-100">
        </div>
      </aside>
    </>
  )
  }

  export default SocialMedia;
import {useState, useContext} from "react";
import {IconMenu2, IconX, IconSun, IconMoon, IconToggleLeft, IconToggleRight, IconWorld} from "@tabler/icons-react";
import {LanguageContext} from '../Pages/Home'

function Navbar(props) {
    const language = useContext(LanguageContext);

    const [hamburger, setHamburger] = useState(false);

    const hamburgerMenu = () => {
        setHamburger(!hamburger);
    }

    const pageUp = () => {
        window.scrollTo({top: (0,0)});
    }

    const navLinks = [
        {
            id: 1,
            name: language.menuItem1,
            link: "#hero"
        },
        {
            id: 2,
            name: language.menuItem2,
            link: "#perfil"
        },
        {
            id: 3,
            name: language.menuItem3,
            link: "#projetos"
        },
        {
            id: 4,
            name: language.menuItem4,
            link: "#contato"
        }
    ];

    const languages = [
        {
            id: 1,
            nome: "Português",
            valor: "pt-br"
        },
        {
            id: 2,
            nome: "English",
            valor: "en"
        },
    ];

    return (
    <>
    <header>
    
        {/* Desktop Nav */}
        <nav className="bg-principal-500 dark:bg-principal-400 shadow-md w-full z-20 fixed flex items-center justify-between p-4 font-mono text-sm">
            
            <span onClick={pageUp} className="cursor-pointer text-principal-700 dark:text-principal-300 text-2xl font-bold">
                EL
            </span>
            
            <ul className="gap-8 text-principal-400 dark:text-principal-100 hidden md:flex">
                
                {navLinks.map((item) => (
                    <li key={item.id}>
                        <span className="text-principal-700 dark:text-principal-300 mr-1">0{item.id}.</span>
                        <a href={item.link} className=" hover:text-principal-700 dark:hover:text-principal-300">{item.name}</a>
                    </li> 
                ))}

                <IconSun className="-mr-7" size={20} />
                <li key={"DarkMode"} className={`dark:hover:text-principal-300 cursor-pointer hover:text-principal-700`} onClick={props.toggleDarkMode}>
                    <IconToggleLeft className="flex dark:hidden" size={20} />
                    <IconToggleRight className="hidden dark:flex" size={20} />
                </li>
                <IconMoon className="-ml-7" size={20} />

                <li key={"Language"} className="flex">
                    <IconWorld className="mr-1" size={20} />
                        <select onChange={props.changeLanguage} className='bg-principal-500 dark:bg-principal-400 appearance-none cursor-pointer hover:text-principal-700 dark:hover:text-principal-300'>
                            {languages.map((item) =>
                            <option className="text-principal-400 dark:text-principal-100" key={item.id} value={item.valor}>{item.nome}</option>
                            )}
                        </select>
                </li>
            </ul>

            {/* Hamburger Menu */}
            <ul className="text-principal-400 dark:text-principal-100 flex md:hidden">
                <li key={"HamburgerMenu"} onClick={() => hamburgerMenu()}>
                    <IconMenu2 className="dark:hover:text-principal-300 hover:text-principal-700 cursor-pointer" size={30} />
                </li>
            </ul>
        
        </nav>

        

        {/* Mobile nav */}
        <nav className={`items-center justify-center text-center gap-10 w-full h-full z-30 bg-principal-500 dark:bg-principal-400 text-principal-400 dark:text-principal-100 fixed font-mono text-lg ${hamburger ? "flex" : "hidden"}`}>
            
            <span onClick={() => hamburgerMenu()} className={"absolute top-4 right-4"}>
                <IconX width={30} height={30} />
            </span>

            <ul className="flex flex-col gap-10">
                {navLinks.map((item) => (
                    <li key={item.id} onClick={() => hamburgerMenu()}>
                        <a href={item.link}>{item.name}</a>
                    </li>
                ))}
                
                <div id="mobileDarkMode" className="flex gap-2 justify-center items-center">
                <IconSun size={25} />
                <li key={"DarkMode"} onClick={props.toggleDarkMode}>
                    <IconToggleLeft className="flex dark:hidden" size={25} />
                    <IconToggleRight className="hidden dark:flex" size={25} />
                </li>
                <IconMoon size={25} />
                </div>

                <div id="mobileLanguage" className="flex items-center justify-center">
                    <IconWorld value="pt-BR" className="text-principal-400 dark:text-principal-100 mr-1" size={25} />
                    <li>
                        <select onChange={props.changeLanguage} className="bg-principal-500 dark:bg-principal-400 appearance-none">
                            {languages.map((item) =>
                            <option key={item.id} value={item.valor}>{item.nome}</option>
                            )}
                        </select>
                    </li>
                </div>
            
            </ul>
        </nav>
        
    </header>
    </>
    );
  }
  
  export default Navbar;
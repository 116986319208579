function Footer() {
    return (
      <>
        <div className="flex flex-col items-center justify-center bg-principal-500 dark:bg-principal-400 p-6">
          <p className="text-principal-700 dark:text-principal-300 font-mono">Projetado e construído por Eduardo Lins</p>
        </div>
      </>
    )
    }
  
    export default Footer;
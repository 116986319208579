import {useContext} from "react";
import {LanguageContext} from '../Pages/Home';
import Tooltip from './Tooltip';

function Hero() {
    const language = useContext(LanguageContext);

    const skillsIcons = [
        {
            id: 1,
            name: "HTML",
            img: "https://skillicons.dev/icons?i=html",
        },
        {
            id: 2,
            name: "CSS",
            img: "https://skillicons.dev/icons?i=css",
        },
        {
            id: 3,
            name: "JavaScript",
            img: "https://skillicons.dev/icons?i=javascript",
        },
        {
            id: 4,          
            name: "React",
            img: "https://skillicons.dev/icons?i=react",
        },
        {
            id: 5,          
            name: "Tailwind",
            img: "https://skillicons.dev/icons?i=tailwind",
        },
        {
            id: 6,
            name: "Redux",
            img: "https://skillicons.dev/icons?i=redux",
        },
      ];

    return (
        <>
        <section id="hero" className="flex items-center justify-center w-full h-screen">
            <div className="container flex flex-col p-5 mt-16 lg:w-3/5 md:w-3/4">
                <p className="font-mono font-medium text-lg text-principal-700 dark:text-principal-300 mb-6">{language.inicioApresentacao}</p>
                <h1 className="lg:text-5xl xl:text-6xl text-3xl font-bold text-principal-400 dark:text-principal-100">{language.inicioNome}</h1>
                <h2 className="lg:text-5xl xl:text-6xl text-3xl font-bold text-principal-600 dark:text-principal-200 mb-6">{language.inicioDescricao}</h2>
                <p className="lg:w-7/12 text-principal-600 dark:text-principal-200 lg:text-base xl:text-lg">{language.inicioResumo}</p>
                <div className="mt-16 flex gap-3 items-center flex-wrap">
                    <p className="font-mono font-medium text-lg text-principal-700 dark:text-principal-300">{language.inicioTecnologias}</p>
                    {skillsIcons.map((icon) => (
                        <Tooltip key={icon.id} message={icon.name}>
                            <img title={icon.name} className="pointer-events-none" src={icon.img} width={'36px'} height={'auto'} alt="" />
                        </Tooltip>
                    ))}
                 </div>
            </div>
        </section>
        </>
    )
}

export default Hero;
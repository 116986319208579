import imgPerfil from '../Assets/perfil.jpg';
import {useContext} from "react";
import {LanguageContext} from '../Pages/Home';

function Perfil() {
  const language = useContext(LanguageContext);

  return (
    <>
    <section id="perfil" className="bg-principal-500 dark:bg-principal-400 h-screen w-full flex justify-center items-center lg:pt-0">
        <div className="container gap-4 flex flex-col p-5 lg:w-3/5 md:w-3/4">
            <div className="flex items-center gap-2">
                <p className="font-mono font-medium text-lg lg:text-2xl text-principal-700 dark:text-principal-300">02. </p>
                <p className="font-mono font-medium text-lg lg:text-2xl text-principal-400 dark:text-principal-100">{language.menuItem2}</p>
                <div className="w-1/4 border-b border-principal-400 dark:border-principal-100"></div>
            </div>
            <div className='flex flex-col-reverse lg:flex-row gap-6'>
                <div className='flex flex-col gap-6 lg:w-2/3 text-principal-600 dark:text-principal-200 xl:text-base lg:text-sm text-xs'>
                <p>{language.sobrePrimeiro}</p>
                <p>{language.sobreSegundo}</p>
                <p>{language.sobreTerceiro}</p>
                </div>
                <div className='w-40 xl:w-80 lg:w-60 flex lg:self-center'>
                    <img src={imgPerfil} className="rounded-3xl" alt="" />
                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default Perfil;
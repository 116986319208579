import {useState, useEffect, createContext} from 'react';
import Navbar from '../Components/Navbar';
import SocialMedia from '../Components/SocialMedia'
import Hero from '../Components/Hero';
import Perfil from '../Components/Perfil';
import Projetos from '../Components/Projetos';
import Contato from '../Components/Contato';
import Footer from '../Components/Footer';
export const LanguageContext = createContext();

function Home() {
    const [darkMode, setDarkMode] = useState(true);
    const [language, setLanguage] = useState("pt-br");
    const [jsonLanguage, setJsonLanguage] = useState(require(`../Locale/${language}.json`));

    function toggleDarkMode() {
        setDarkMode(!darkMode);
    }

    function changeLanguage(e) {
        setLanguage(e.target.value);
    }
    
    useEffect(() => {
        setJsonLanguage(require(`../Locale/${language}.json`));
    }, [language]);

    useEffect(() => {
        darkMode ? document.documentElement.classList.add('dark') : document.documentElement.classList.remove('dark');
    }, [darkMode]);

    return (
        <>
            <LanguageContext.Provider value={jsonLanguage}>
                <Navbar toggleDarkMode={toggleDarkMode} changeLanguage={changeLanguage} />
                <Hero />
                <Perfil />
                <Projetos />
                <Contato />
                <Footer />
            </LanguageContext.Provider>
            <SocialMedia />
        </>
    );
}

export default Home;
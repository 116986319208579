import {useContext} from "react";
import {LanguageContext} from '../Pages/Home';

function Contato() {
    const language = useContext(LanguageContext);

    return (
      <>
      <section id="contato" className="bg-principal-500 dark:bg-principal-400 w-full flex justify-center items-center h-screen">
        <div className="container flex flex-col p-5 mt-16 lg:w-3/5 md:w-3/4 items-center justify-center">
          <div className="flex">
              <p className="font-mono font-medium text-lg lg:text-2xl text-principal-700 dark:text-principal-300">04. </p>
              <p className="font-mono font-medium text-lg lg:text-2xl text-principal-400 dark:text-principal-100">{language.menuItem4}</p>
              </div>
  
          <h2 className="lg:text-5xl xl:text-6xl text-3xl font-bold text-principal-600 dark:text-principal-200 mt-6 mb-6">{language.contatoTitulo}</h2>
          <p className="lg:w-7/12 text-principal-600 dark:text-principal-200 lg:text-base xl:text-lg">{language.contatoDesc}</p>
          <a href="mailto:linse1331@gmail.com?subject=Contato Website"><button className="p-2 mt-20 border-2 border-principal-700 font-mono text-principal-700 hover:text-principal-400 dark:hover:text-principal-100 dark:text-principal-300 dark:border-principal-300">{language.contatoBtn}</button></a>
        </div>
      </section>
      </>
    );
  }
  
  export default Contato;
  
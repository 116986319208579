import {useContext} from "react";
import {LanguageContext} from '../Pages/Home';

function Projetos() {
    const language = useContext(LanguageContext);
    return (
    <>
    <section id="projetos" className="bg-principal-500 dark:bg-principal-400 w-full h-screen flex justify-center items-center">
      <div className="container flex flex-col p-5 lg:w-3/5 md:w-3/4">
      <div className="flex items-center gap-2">
                <p className="font-mono font-medium text-lg lg:text-2xl text-principal-700 dark:text-principal-300">03. </p>
                <p className="font-mono font-medium text-lg lg:text-2xl text-principal-400 dark:text-principal-100">{language.menuItem3}</p>
                <div className="w-1/4 border-b border-principal-400 dark:border-principal-100"></div>
            </div>
            <p className="font-mono xl:text-base lg:text-sm text-xs text-principal-400 dark:text-principal-100 mt-6">{language.projetosAguarde}</p>
      </div>
    </section>
    </>
  );
}

export default Projetos;